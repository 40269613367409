export const getGoogleMapDefaultCities = async (
  googleMaps,
  selectedPlaces = [],
  shouldMapData = true
) => {
  // We can't use another API to fetch a set list of cities, so we loop over a list of strings
  // and autocomplete them to get the correct IDs
  // TODO: Move this list of cities out of the codebase (in database or Loco)
  const cities = await Promise.all(
    [
      "Paris, France",
      "Marseille, France",
      "Lyon, France",
      "Toulouse, France",
      "Nice, France",
      "Nantes, France",
      "Bordeaux, France",
    ].map(
      (address) =>
        new Promise((resolve, reject) => {
          if (googleMaps) {
            const autoCompleteService =
              new googleMaps.places.AutocompleteService();

            if (autoCompleteService) {
              autoCompleteService.getPlacePredictions(
                {
                  input: address,
                  location: new googleMaps.LatLng(0, 0),
                  radius: 20,
                  types: ["(cities)"],
                  componentRestrictions: { country: "" },
                },
                (googleSuggests) => {
                  let formattedResults = googleSuggests?.filter(
                    (suggest) =>
                      suggest.types.includes("sublocality") === false &&
                      suggest.types.includes("postal_code") === false
                  );
                  if (shouldMapData) {
                    formattedResults = formattedResults.map((suggest) => ({
                      label: suggest.description,
                      name: suggest.description,
                      value: suggest.place_id,
                      placeId: suggest.place_id,
                    }));
                  }
                  resolve(formattedResults[0]);
                }
              );
            }
          }
        })
    )
  );

  return selectedPlaces?.length > 0
    ? [
        ...selectedPlaces,
        ...cities?.filter(
          (city) =>
            !selectedPlaces
              .map(({ label, name }) => label ?? name)
              .includes(city.description ?? city.name)
        ),
      ]
    : cities;
};
