export const getGoogleMapAddress = async (
  googleMaps,
  address,
  selectedPlaces = []
) =>
  new Promise((resolve, reject) => {
    if (googleMaps) {
      const autoCompleteService = new googleMaps.places.AutocompleteService();

      if (autoCompleteService) {
        autoCompleteService.getPlacePredictions(
          {
            input: address,
            location: new googleMaps.LatLng(0, 0),
            radius: 20,
            types: ["(cities)"],
            componentRestrictions: { country: "" },
          },
          (googleSuggests) => {
            const formattedResults = googleSuggests
              ?.filter(
                (suggest) =>
                  suggest.types.includes("sublocality") === false &&
                  suggest.types.includes("postal_code") === false &&
                  !selectedPlaces
                    .map(({ label, name }) => label ?? name)
                    .includes(suggest.description)
              )
              .map((suggest) => ({
                label: suggest.description,
                name: suggest.description,
                value: suggest.place_id,
                placeId: suggest.place_id,
              }));

            resolve(
              selectedPlaces?.length > 0
                ? [...selectedPlaces, ...formattedResults]
                : formattedResults
            );
          }
        );
      }
    }
  });
