import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import GoogleMapLoader from 'react-google-maps-loader';
import useValidations from '@/utils/validations/useValidations';
import Icon from '@/components/Icon';
import { getCriterionLabel } from '../utils';
import { FormAutocompleteField } from '@/components/FinalFormFields/FormAutocompleteField';
import {
  getSelectedLocation
} from '@/scenes/Event/scenes/Recruiter/scenes/Preparation/scenes/Offer/containers/CreateOfferModal/components/CreateOfferForm/components/CriteriaFields/components/helpers/locations';
import { getGoogleMapAddress } from '@/features/gmaps/helpers/getGoogleMapAddress';
import { getGoogleMapDefaultCities } from '@/features/gmaps/helpers/getGoogleMapDefaultCities';

function MobilityField({ criterion, googleMaps, showHelper, disabled = true, required = true }) {
  const { isFilled } = useValidations();
  const formState = useFormState();
  const [options, setOptions] = useState(getSelectedLocation(formState.values?.locations));
  const timeout = useRef();
  const form = useForm();

  useEffect(() => {
    if (googleMaps) {
    (async () => {
      const formatedSelectedLocations = getSelectedLocation(formState.values?.locations);
      setOptions(await getGoogleMapDefaultCities(googleMaps, formatedSelectedLocations));
      })();
    }
  }, [googleMaps, formState.values.locations]);
  
  // Input sends values as string[] so we need to map it with our real values.
  const onChangeMobility = (newValues) => {
    form.change('locations', options.filter(({ value }) => newValues.includes(value)));
  }

  const handleInputChange = (search) => {
    if (search.length >= 3) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }

      timeout.current = setTimeout(async () => {
        const formatedSelectedLocations = getSelectedLocation(formState.values?.locations);
        setOptions(await getGoogleMapAddress(googleMaps, search, formatedSelectedLocations));
      }, 500)
    }
  };
  
  return (
    <div>
      <FormAutocompleteField 
        name="locations"
        disabled={disabled}
        label={getCriterionLabel(criterion)}
        leftIcon={<Icon name={criterion.icon} style={{ width: '24px', height: '24px' }} />}
        required={required}
        validate={value => isFilled(value)}
        options={options}
        onChange={onChangeMobility}
        formatValue={(value) => value?.map((val) => val._id ?? val?.value ?? val)}
        asynchronousSearch={handleInputChange}
        multiple
      />
    </div>
  );
}

MobilityField.propTypes = {
  criterion: object,
  googleMaps: object,
  showHelper: func,
  disabled: bool
};

MobilityField.defaultProps = {
  criterion: {},
  googleMaps: {},
};

export default GoogleMapLoader(MobilityField, {
  libraries: ['places'],
  key: process.env.FRONT_GOOGLE_MAPS_API_KEY,
});
